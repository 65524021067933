var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"history-order"},[_c('div',{staticClass:"fix-header"},[_c('a',{staticClass:"fix-header-back-icon",on:{"click":_vm.closed}},[_c('i',{staticClass:"fas fa-chevron-left"})]),_c('p',{staticClass:"fix-header-title"},[_vm._v(_vm._s(_vm.$t("historyOrder.historyorder")))])]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"history-order-groups"},_vm._l((_vm.orders),function(order){return _c('div',{key:order.SalId,staticClass:"history-order-group",on:{"click":function($event){return _vm.goTrade(order.SalId)}}},[_c('div',{staticClass:"history-order-header",class:_vm.getStatusClass(order)},[_c('p',[_vm._v(" "+_vm._s(order.OnlineOrderStatusText)+" ")]),(
              order.OnlineOrderStatusType == 2 &&
              order.OnlineOrderRejectReason
            )?_c('p',{staticClass:"reject-reason"},[_vm._v(" "+_vm._s("(" + order.OnlineOrderRejectReason + ")")+" ")]):_vm._e()]),_c('div',{staticClass:"history-order-content"},[_c('div',{staticClass:"history-order-text-group"},[_c('label',{attrs:{"for":"salNumber"}},[_vm._v(_vm._s(_vm.$t("historyOrder.ordernumber")))]),_c('p',{attrs:{"id":"salNumber"}},[_vm._v(_vm._s(order.SalNumber))])]),_c('div',{staticClass:"history-order-text-group"},[_c('label',{attrs:{"for":"onlineOrderTimeText"}},[_vm._v(_vm._s(_vm.$t("historyOrder.createtime")))]),_c('p',{attrs:{"id":"onlineOrderTimeText"}},[_vm._v(_vm._s(order.OnlineOrderTimeText))])]),_c('hr'),_c('div',{staticClass:"history-order-text-group"},[_c('label',{attrs:{"for":"eatTypeText"}},[_vm._v(_vm._s(_vm.$t("historyOrder.eattype")))]),_c('p',{attrs:{"id":"eatTypeText"}},[_vm._v(_vm._s(order.EatTypeText))])]),(
              (order.OnlineOrderStatusType == 0 ||
                order.OnlineOrderStatusType == 1) &&
              order.EatType != 0
            )?_c('div',{staticClass:"history-order-text-group"},[_c('label',{attrs:{"for":"reserveTimeText"}},[_vm._v(_vm._s(_vm.$t("historyOrder.reservetime")))]),_c('p',{attrs:{"id":"reserveTimeText"}},[_vm._v(_vm._s(order.EstimatedPickupTimeText))])]):_vm._e(),(order.OnlineOrderStatusType == 3)?_c('div',{staticClass:"history-order-text-group"},[(order.EatType != 0)?_c('label',{attrs:{"for":"salTimeText"}},[_vm._v(_vm._s(_vm.$t("historyOrder.reservetime")))]):_c('label',{attrs:{"for":"salTimeText"}},[_vm._v(_vm._s(_vm.$t("historyOrder.checkouttime")))]),_c('p',{attrs:{"id":"salTimeText"}},[_vm._v(_vm._s(order.SalTimeText))])]):_vm._e(),_c('div',{staticClass:"history-order-text-group"},[_c('label',{attrs:{"for":"memo"}},[_vm._v(_vm._s(_vm.$t("historyOrder.memo")))]),_c('p',{attrs:{"id":"memo"}},[_vm._v(_vm._s(order.Memo))])]),_c('hr'),_c('p',{staticClass:"history-order-total"},[_vm._v(" "+_vm._s(_vm.$t("historyOrder.total") + " NT")),_c('span',[_vm._v(_vm._s(order.Total))])])])])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }